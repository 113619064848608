import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

const Language = styled.span`
    width: 75%;
    height: 19px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #48c9d1;
`

const Container = styled.a``

export default function LanguageSwitcher() {
    // quick and dirty hack so we don't have state both here and in location bar
    const selectedLanguage =
        typeof window !== 'undefined' && window.location.href.includes('/en')
            ? 'en'
            : 'hr'
    const otherLanguage = selectedLanguage === 'hr' ? 'en' : 'hr'

    return (
        <Container>
            <Language
                onClick={event => {
                    event.preventDefault()
                    navigate(`/${otherLanguage}`)
                }}
            >
                {otherLanguage.toUpperCase()}
            </Language>
        </Container>
    )
}
