import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Headroom from 'react-headroom'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import 'normalize.css'
import './Layout.css'
import LanguageSwitcher from './LanguageSwitcher'
import CookieConsent from 'react-cookie-consent'
import { StyledLink } from './Link'
import CookieEn from '../locales/en/CookieConsent.json'
import CookieHr from '../locales/hr/CookieConsent.json'

const HeaderContent = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const Consent = styled.div`
    font-family: Montserrat;
    font-size: small;
`

const Rozeta = styled.div`
    height: 24px;
    font-family: Montserrat;
    font-size: 20px;
    text-align: left;
    color: #48c9d1;
    padding-top: 24px;
    padding-bottom: 12px;
`
const Footer = styled.div`
    overflow: hidden;
    height: 40px;
    background-color: #ffffff;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1);
`

const Copyrights = styled.div`
    max-width: 229px;
    height: 19px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #48c9d1;
    padding-top: 11px;
    padding-bottom: 10px;
`

const Header = styled.header`
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
`

const LanguageSwitcherContainer = styled.span`
    float: right;
`

const NavElement = styled.span`
    width: 75%;
    height: 19px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #48c9d1;
    margin-right: 19px;
`

const Navigation = () => (
    <div style={{ paddingTop: `26px` }}>
        <NavElement>
            <StyledLink exact strict to="/" activeStyle={{ color: `#333333` }}>
                Home
            </StyledLink>
        </NavElement>
        <NavElement>
            <StyledLink to="/blog" activeStyle={{ color: `#333333` }}>
                Blog
            </StyledLink>{' '}
        </NavElement>
        <LanguageSwitcherContainer>
            <LanguageSwitcher />
        </LanguageSwitcherContainer>
    </div>
)

const header = data => (
    <Headroom>
        <Header>
            <Container>
                <HeaderContent>
                    <Rozeta>{data.site.siteMetadata.title}</Rozeta>
                    <Navigation />
                </HeaderContent>
            </Container>
        </Header>
    </Headroom>
)

const Container = styled.div`
    margin: 0 auto;
    max-width: 940px;
    @media screen and (max-device-width: 940px) {
        padding-right: 8px;
        padding-left: 8px;
    }
`

const ContentContainer = styled(Container)`
    min-height: 100vh;
`

const WhiteBackground = styled.div``

const Layout = ({ children, Background, language }) => {
    const LayoutBackground = Background || WhiteBackground

    let cookieConsentText = CookieEn['text']
    let cookieAccept = CookieEn['accept']
    let cookieDecline = CookieEn['decline']
    if (language === 'hr') {
        translations = CookieHr['text']
        cookieAccept = CookieHr['accept']
        cookieDecline = CookieHr['decline']
    }

    return (
        <StaticQuery
            query={graphql`
                query LayoutQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <LayoutBackground>
                    {header(data)}
                    <Helmet
                        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                        defaultTitle={data.site.siteMetadata.title}
                    />
                    <ContentContainer>{children}</ContentContainer>
                    <Footer>
                        <Container>
                            <Copyrights>
                                All rights reserved. ® Rozeta
                            </Copyrights>
                        </Container>
                    </Footer>
                    <CookieConsent
                        style={{
                            width: '100%',
                            width: '100vw',
                        }}
                        enableDeclineButton={true}
                        declineButtonText={cookieDecline}
                        buttonText={cookieAccept}
                        cookieName={`rozeta-cookie-consent`}
                        setDeclineCookie={false}
                        declineButtonStyle={{ marginRight: '20em' }}
                        flipButtons={true}
                        onDecline={() => {
                            {
                                /* eslint-disable-next-line no-undef */
                            }
                            if (window.gaOptout) {
                                window.gaOptout()
                            }
                        }}
                    >
                        <Consent>{`${cookieConsentText}`}</Consent>
                    </CookieConsent>
                </LayoutBackground>
            )}
        />
    )
}

Layout.propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
    route: PropTypes.object,
    data: PropTypes.object,
    Background: PropTypes.object,
}

export default Layout
